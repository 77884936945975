<template>
    <div>
        <b-row>
            <b-col cols="6">
                <b-form-group :label="$t('type')">
                    <b-input-group >
                        <div class="label-as-input">{{ form.type === 'individual' ? $t('individual') : $t('institutional') }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('username')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.username) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('nationality_type')">
                    <b-input-group>
                        <div class="label-as-input">{{ form.nationality_type === 'TR' ? $t('turkey') : $t('abroad') }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('national_id')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.national_id) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('passport_number')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.passport_number) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('name')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.name) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('surname')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.surname) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('birthdate')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.birthdate) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('mobile_number')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.mobile_tel) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('personal_email')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.personal_email) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('email')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.email) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('mother_name')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.mother_name) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('father_name')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.father_name) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('tax_number')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.tax_number) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    //Service
    import CertificatePersonService from '@/services/CertificatePersonService';

    export default {
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                form: {
                    type: null,
                    name: null, 
                    surname: null,
                    username: null,
                    personal_email: null,
                    mobile_tel: null,
                    email: null,
                    nationality_type: null,
                    national_id: null,
                    passport_number: null,
                    tax_number: null,
                    birthdate: null,
                    mother_name: null,
                    father_name: null
                }
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                this.formLoading = true;
                CertificatePersonService.get(id)
                                .then((response) => {
                                    this.form = response.data.data;
                                    this.formLoading = false;
                                })
                                .catch((error) => {
                                    this.showErrors(error)
                                });
            }
        }
    }
</script>
