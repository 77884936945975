<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div class="row">
                <div class="col-6">
                    <ValidationProvider name="type" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('type')">
                            <parameter-selectbox
                                code="certificate_person_types"
                                v-model="form.type"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="username" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('username')">
                            <b-form-input
                                v-model="form.username"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="nationality_type" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('nationality_type')">
                            <b-form-select 
                                v-model="form.nationality_type"
                                :options="[{ text: $t('turkey'), value: 'TR' }, { text: $t('abroad'), value: 'YU' }]"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="national_id" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('national_id')">
                            <b-form-input
                                v-model="form.national_id"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="passport_number" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('passport_number')">
                            <b-form-input
                                v-model="form.passport_number"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('name')">
                            <b-form-input
                                v-model="form.name"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="surname" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('surname')">
                            <b-form-input
                                v-model="form.surname"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="birthdate" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('birthdate')">
                            <b-form-input
                                v-model="form.birthdate"
                                type="date"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="mobile_tel" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('mobile_number')">
                            <b-form-input
                                type="number"
                                v-model="form.mobile_tel"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="personal_email" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('personal_email')">
                            <b-form-input
                                type="email"
                                v-model="form.personal_email"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="email" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('email')">
                            <b-form-input
                                type="email"
                                v-model="form.email"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="mother_name" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('mother_name')">
                            <b-form-input
                                v-model="form.mother_name"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="father_name" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('father_name')">
                            <b-form-input
                                v-model="form.father_name"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="tax_number" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('tax_number')">
                            <b-form-input
                                v-model="form.tax_number"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>

        <div class="col-6 mt-3 d-flex">
            <b-button
                @click="createForm"
                type="button"
                :disabled="formLoading"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
    //Component
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import CertificatePersonService from "@/services/CertificatePersonService";

    export default {
        components: {
            ParameterSelectbox, ValidationProvider, ValidationObserver,
        },
        data() {
            return {
                formLoading: false,
                form: {
                    type: null,
                    name: null, surname: null,
                    username: null,
                    personal_email: null,
                    mobile_tel: null,
                    email: null,
                    nationality_type: null,
                    national_id: null,
                    passport_number: null,
                    tax_number: null,
                    birthdate: null,
                    mother_name: null,
                    father_name: null
                }
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate()
                if (isValid) {
                    this.formLoading = true;
                    CertificatePersonService.store(this.form)
                                    .then((response) => {
                                        this.$toast.success(this.$t("api." + response.data.message));
                                        this.$emit("createFormSuccess")
                                    })
                                    .catch((error) => {
                                        this.showErrors(error, this.$refs.formModalValidate)
                                    }).finally(() => {
                        this.formLoading = false;
                    });
                }
            }
        }
    }
</script>
